<!-- @Author: Yu_Bo -->
<template>
	<div class='operation_log'>
    <!-- 标题 -->
    <div class="vue_title">操作日志</div>
    <div class="operation_main">
      <!--  -->
      <div class="main_condition">
        <div class="cond_left">
          <el-input v-model="account" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="搜索操作人"></el-input>
          <el-input v-model="mobile" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="搜索操作账号"></el-input>
          <el-input v-model="title" size="small" @keyup.enter.native="search" @clear='search' :clearable="true" placeholder="搜索标题"></el-input>
        </div>
        <div class="cond_right">
          <el-button type="primary" class="btnBgColor_grey" size="small" icon="el-icon-upload2" @click='exportBtn'>导出</el-button>
        </div>
      </div>
      <!--  -->
      <div class="main_table">
        <el-table :data="list" v-loading="loading" style="width: 100%"
          ref="multipleTable" @selection-change="handleSelectionChange"
          header-cell-class-name="table-header" cell-class-name="cell-class">
          <template slot="empty">
            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
          </template>
          <el-table-column type="selection" align="center" width="55"></el-table-column>
          <el-table-column prop="account" label="操作人"></el-table-column>
          <el-table-column prop="mobile" label="操作账号"></el-table-column>
          <el-table-column prop="create_time" label="操作日期"></el-table-column>
          <el-table-column prop="ip" label="IP地址"></el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column prop="remark" label="操作记录"></el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="page_box" v-if="total" style="padding-bottom: 20px;">
        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
      </div>
    </div>
	</div>
</template>

<script>
	import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
	import PagingPage from '@/components/paging_page/paging_page.vue'//分页
	export default {
		components: {EmptyData, PagingPage},
		props: {},
		data() {
			return{
        account:'',
        mobile:'',
        title:'',
        //
        list:[],
        loading:false,
        ids:[],
        multipleSelection:[],
        //
        page: 1,
        limit: 10,
        total:0,
			}
		},
		computed: {},
		watch: {},
		created() {
      this.search()
    },
		mounted() {},
		methods: {
      search() {
      	this.page = 1
      	this.list = []
      	this.total = 0
      	this.getList()
      },
      getList(){
        var that = this
        that.loading=true
        var params = {
          account:that.account,
          mobile:that.mobile,
          title:that.title,
          page: that.page,
          limit: that.limit,
        }
        that.$purviewApi.systemLog(params).then(res => {
          that.loading = false
          if (res.code == 1000) {
            that.list=res.result?.data
            that.total=res.result?.total
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 表单选择
      handleSelectionChange(val) {
        this.ids=[]
        val.forEach(item => {
          this.ids.push(item.id)
        })
        this.multipleSelection = val;
      },
      // 改变每页条数
      sizeChangeBtn(val){
        this.limit = val
        this.page = 1
        this.getList()
      },
      // 改变页数
      currentChangeBtn(val){
        this.page = val
        this.getList()
      },
      // 导出
      exportBtn(){
        var that = this
        var params = {
          account:that.account,
          mobile:that.mobile,
          title:that.title,
          ids:that.ids.join(','),
        }
        that.$purviewApi.logExport(params).then(res => {
          return
        })
      },
    },
	}
</script>

<style lang='scss' scoped>
	.operation_log{
    width: 100%;
    .operation_main{
      width: 100%;
      padding: 0 20px;
      .main_condition{
        width: 100%;
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cond_left{
          display: flex;
          align-items: center;
          .el-button{
            padding: 8px 10px;
            margin-right: 20px;
          }
          .el-select{
            margin-right: 20px;
            width: 160px;
          }
          .el-input{
            margin-right: 20px;
            width: 160px;
          }
        }
        .cond_right{
          .el-button{
            padding: 8px 10px;
          }
        }
      }
    }
	}
</style>
